﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    tokens,
    Menu,

    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableCell,
    TableBody,
    TableCellLayout,
    TableSelectionCell,
    useTableFeatures,
    useTableSelection,
    TableColumnDefinition,
    createTableColumn,
    TableCellActions,
    TableCellLayoutProps,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    useId,
    Label,
    Input,
    typographyStyles,
    ToolbarDivider,
    Breadcrumb,
    isTruncatableBreadcrumbContent,
    truncateBreadcrumbLongName,
    BreadcrumbItem,
    Tooltip,
    BreadcrumbButton,
    BreadcrumbDivider,
    useOverflowMenu,
    PartitionBreadcrumbItems,
    partitionBreadcrumbItems,
    Overflow,
    OverflowItem,
    OverflowItemProps,
    useIsOverflowItemVisible,
    MenuItemLink,
    Combobox,
    ComboboxProps,
    Checkbox,
    CheckboxProps,
} from "@fluentui/react-components";

import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps } from "../entitiesAPI";
import { AddRegular, DeleteRegular, FolderRegular, ArrowDownloadRegular, ArrowDownloadFilled, DocumentRegular, Dismiss24Regular, DismissRegular, EditRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular, bundleIcon, EditFilled, DeleteFilled, CheckRegular, PageFit20Regular, Document20Regular, MoreHorizontalFilled, MoreHorizontalRegular } from "@fluentui/react-icons";
import { EntryType } from "../filemanager/filemanager";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { CreateFolderLinkingBit, DeleteFolderLinkingBit, DeleteLinkingBit, GetAllPhraseLibraries, GetFolderLinkingBit, RenameLinkingBit, SearchPhraseLibraries } from "../../apiFunctions/baseFunctions/baseFunctions";
import { IEntryType } from "../ddentities";
import { FileIconType, getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { Icon } from '@fluentui/react/lib/Icon';


type PhraseSelectorProps = {
    action: string
}

export const PhraseLibrarySelector: React.FC<PhraseSelectorProps> = (props) => {

    const userObj: IUserProps = usePersistentStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;

    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [items, setItems] = React.useState<item[]>([]);
    const [libraries, setLibraries] = React.useState<libraries[]>([]);
    const [currentLibraryId, setCurrentLibraryId] = React.useState<string>("");
    const [selectedItem, setSelectedItem] = React.useState<item>(null);
    
    const [customSearch, setCustomSearch] = React.useState<string | undefined>();
    const [matchingOptions, setMatchingOptions] = React.useState([...items]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showNotes, setShowNotes] = React.useState<CheckboxProps["checked"]>(false);
    interface item {
        id: string;
        phrase: string;
        note: string;
    }  

    interface libraries {
        id: string;
        name: string

    }  

    useEffect(() => {

        const init = async () => {

            setEndpoints(endPoints);
            if (!isUserAuthenticated) {
                const currentpath = window.location.pathname + window.location.search;

                if (!userObj) navigate(`/user?nextUrl=${currentpath}`, { replace: true });
                else {
                    navigate(`/user?nextUrl=${currentpath}`, { replace: true });
                }
            } else {



            }
            await refreshLibraries();
        }
        init();

    }, []);


    async function refreshLibraries() {

        var authObject = await getAuthObject();
      

        var _libs = await GetAllPhraseLibraries(authObject);
        var tmpItems: libraries[] = [];
        for (var i = 0; i < _libs.length; i++) {

            if (_libs[i].enabled) {
                var f: libraries = {
                    id: _libs[i].id,
                    name: _libs[i].name
                }
                tmpItems.push(f);
            }
            
        }
        setLibraries(tmpItems);
        if (tmpItems.length ==1) {
            setCurrentLibraryId(tmpItems[0].id);

        }
    }

    async function refreshPhrases() {

        var authObject = await getAuthObject();
        let pageSize = '500';

        const queryParams = new URLSearchParams({
            searchTerm: '',
            continuationToken:  '',
            pageSize: pageSize
        }).toString();

        var _result = await SearchPhraseLibraries(authObject,currentLibraryId,queryParams);
        var tmpItems: item[] = [];
        for (var i = 0; i < _result.result.length; i++)
        {

            var f: item =
            {
                id: _result.result[i].id,
                phrase: _result.result[i].Phrase,
                note: _result.result[i].Note
            }

            tmpItems.push(f);
            

        }
        tmpItems.sort((a, b) => (a.phrase > b.phrase) ? 1 : -1);
        setItems(tmpItems);
        setMatchingOptions(tmpItems);
    }


    React.useEffect(() => {

        const getItems = async () => {
            await refreshPhrases();
            setCustomSearch(undefined);
            setIsLoading(false);
            setSelectedItem(null);
        }

        if(currentLibraryId!="")
            getItems();

        

    }, [currentLibraryId,showNotes])

    React.useEffect(() => {
      

    },[selectedItem])
    


    function changeDir(item: libraries) {
        setCurrentLibraryId(item.id);

    }

    const useStyles = makeStyles({
        option: {
            height: '20px', // Set a fixed height
           /* overflow: 'hidden',*/
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },

        root: {
            // Stack the label above the field
            display: "flex",
            flexDirection: "column",
            // Use 2px gap below the label (per the design system)
            ...shorthands.gap("2px"),
            // Prevent the example from taking the full width of the page (optional)
            maxWidth: "400px",
        },
        grid: {

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',



        },
        headerContainer:
        {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: tokens.spacingHorizontalM,
            marginRight: tokens.spacingHorizontalM,
            /*width:"100dvw"*/

        },
        headerContainerTop:
        {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: tokens.spacingHorizontalM,
            marginRight: tokens.spacingHorizontalM,


        },
        headerColumn1: {
            marginRight: 'auto'
        },
        overflowMenuItem: {
            "& .fui-BreadcrumbItem": {
                "& .fui-Button": { width: "100%", textAlign: "left" },
                "& .truncateText": {
                    fontSize: typographyStyles.body1.fontSize,
                    fontWeight: typographyStyles.body1.fontWeight,
                    fontFamily: typographyStyles.body1.fontFamily,
                    lineHeight: typographyStyles.body1.lineHeight,
                    width: "100%",
                },
            },
        },
        resizableArea: {
            width: "calc(100% - 10px)",
            display: "flex",
            flexWrap: "nowrap",
        },





    });

    const styles = useStyles();


   


    
    function createWebViewData(action, id, text) {
        return {
            action: action,
            userId: userObj.userid,
            customerID: userObj.customerid,
            requestToken: userObj.requestToken,
            phraselib_id: id,
            phraseText : text

        };
    }




    function Header() {
        

        return (
            <div>
                <div className={styles.headerContainerTop} >
                    <DialogTitle style={{ paddingBottom: tokens.spacingVerticalL, marginTop: tokens.spacingVerticalM }}>{props.action === "select" ? "Link phrase" : props.action === "manager" ? "Manage" : "Add"} </DialogTitle>
                    <Toolbar
                        style={{
                            marginBottom: tokens.spacingVerticalL,
                            marginTop: tokens.spacingVerticalM
                        }}
                    >
                        <ToolbarButton
                            icon={<DismissRegular />} onClick={() => {
                                var data = createWebViewData("Cancel", "","");
                                (window as any).chrome.webview.postMessage(JSON.stringify(data));
                            }} />
                    </Toolbar>
                </div>
                <div className={styles.headerContainer}>

                </div>
            </div>
        );
    }


    function Footer() {
        return (
            <div className={styles.grid}
                
            >
                <div>   </div>

                <div>
                    <Button
                        appearance="secondary"
                        onClick={() => {


                            var data = createWebViewData("Cancel", "", "");
                            (window as any).chrome.webview.postMessage(JSON.stringify(data));


                        }}

                        style={{ marginTop: tokens.spacingVerticalL }}

                    >{props.action !== "manager" ? "Cancel" : "Close"}
                    </Button>


                    {props.action === "select" && selectedItem != null &&
                        <Button
                            appearance="primary"
                            onClick={() => {
                                
                                var data = createWebViewData("Select", selectedItem.id, selectedItem.phrase);
                                (window as any).chrome.webview.postMessage(JSON.stringify(data));


                            }}

                            style={{ marginTop: tokens.spacingVerticalL, marginLeft: tokens.spacingHorizontalM }}

                        >Ok
                        </Button>}
                </div>

            </div>
        )
    }
    const dropdownId = useId("dropdown-default");


    const onChange: ComboboxProps["onChange"] = (event) => {
        const value = event.target.value.trim().toLowerCase();
        const matches = items.filter(
            (option) => option.phrase.toLowerCase().includes(value)
        );
        setMatchingOptions(matches);
        if (value.length && matches.length < 1) {
            setCustomSearch(value);
        } else {
            setCustomSearch(undefined);
        }
    };

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {

       
        const matchingOption = data.optionText && items.find(x=>x.id== data.optionValue)!=null;
        if (matchingOption) {
            setCustomSearch(undefined);
            var _item = items.find(x => x.id == data.optionValue);

            setSelectedItem(_item);
        } else {
            setCustomSearch(data.optionText);
           
        }
    };

    const onSelectLibrary: ComboboxProps["onOptionSelect"] = (event, data) => {

        if (data.optionValue === currentLibraryId)
            return;

        setIsLoading(true);
        setCurrentLibraryId(data.optionValue);

       
    };

    return (

        <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
            <div
                style={{
                    height: "100dvh",
                    width: "100vw",
                    background:
                        `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`

                }}
            >
                {Header()}
               
                
                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        borderBottomRightRadius: tokens.borderRadiusXLarge,
                        borderBottomLeftRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,
                        height: "67vh"


                    }}>
                    {libraries.length > 1 &&
                        <div>
                    <div
                        style={{
                            marginBottom: tokens.spacingVerticalL,
                            marginTop: tokens.spacingVerticalM,
                            marginLeft: tokens.spacingHorizontalM,
                            marginRight: tokens.spacingHorizontalM,
                        }}
                    >
                        <label
                          

                            id={dropdownId}>Select phrase library</label>
                    </div>
                    <div
                                style={{ marginLeft: tokens.spacingHorizontalM, marginRight: tokens.spacingHorizontalM, maxHeight: '40vh', overflow:'auto' }} 
                    >
                         <Dropdown
                            style={{ width: '100%' }}
                            listbox={{ style: { maxHeight: '40vh', overflow: 'auto' } }}
                            aria-labelledby={dropdownId}
                            onOptionSelect={onSelectLibrary}
                            placeholder="Select library"
                            {...props}
                        >
                            {libraries.map((option) => (
                                <Option key={option.id} value={option.id} >
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>
                            </div>
                        </div>
                    }
                    {isLoading && <div>loading... </div> }
                    {currentLibraryId != "" && items.length >0  && isLoading===false && <div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: tokens.spacingVerticalL,
                                marginTop: tokens.spacingVerticalM,
                                marginLeft: tokens.spacingHorizontalM,
                                marginRight: tokens.spacingHorizontalM,
                            }}
                        >
                            <label id={dropdownId}>Select phrase</label>
                            <Checkbox
                                label="Show notes in dropdown"
                                checked={showNotes}
                                onChange={(e, state) => setShowNotes(state.checked)}


                            ></Checkbox>
                          
                        </div>
                        <div
                            style={{ marginLeft: tokens.spacingHorizontalM, marginRight: tokens.spacingHorizontalM }}
                        >
                            <Combobox
                                freeform
                                style={{ width: '100%'}}
                                listbox={{ style: { maxHeight: '45vh', overflow:'auto' } }}
                                aria-labelledby={dropdownId}
                                onChange={onChange}

                                placeholder="Search phrase"
                                onOptionSelect={onOptionSelect}
                                {...props}
                            >
                                {customSearch ? (
                                    <Option key="freeform" text={customSearch}>
                                        Search for "{customSearch}"
                                    </Option>
                                ) : null}
                                {matchingOptions.map((option) => (
                                    <Option key={option.id} value={option.id} className={styles.option} >{showNotes ? option.phrase + " [" + (option.note ? option.note : "") +"]":option.phrase}</Option>
                                ))}


                            </Combobox>
                        </div>


                    </div>}
                    {selectedItem != null
                        &&
                    <div>
                    <div
                        style={{
                            marginBottom: tokens.spacingVerticalL,
                            marginTop: tokens.spacingVerticalM,
                            marginLeft: tokens.spacingHorizontalM,
                            marginRight: tokens.spacingHorizontalM,

                                    height: "32vh",
                                    whiteSpace: "pre-line",
                             overflowY: "auto"
                        }}

                    >
                    {selectedItem.phrase}
                            </div>
                    
                    
                  

                     </div>

                    
                    }
                    {selectedItem == null && 
                         <div
                        style={{
                            marginBottom: tokens.spacingVerticalL,
                            marginTop: tokens.spacingVerticalM,
                            marginLeft: tokens.spacingHorizontalM,
                            marginRight: tokens.spacingHorizontalM,
                            height: "32vh",
                                    whiteSpace: "pre-line",
                             overflowY: "auto"
                        }}

                    >
                    </div>
                    }


                        </div>

                    <div
                        style={{
                             marginBottom: tokens.spacingVerticalL,
                            marginTop: tokens.spacingVerticalM,
                            marginLeft: tokens.spacingHorizontalM,
                            marginRight: tokens.spacingHorizontalM,
                            height: "6vh",
                        }}

                    >
                    {selectedItem != null &&
                        <div

                        >
                            <div

                                style={{ height: "2vh", marginBottom: tokens.spacingVerticalL }}
                            >
                                <Label>Guidance note </Label>
                            </div>

                            <div
                                style={{
                                    minHeight: "5vh",
                                    maxHeight: "5vh",
                                    backgroundColor: tokens.colorNeutralBackground1,
                                    color: tokens.colorNeutralForeground1,
                                    borderTopLeftRadius: tokens.borderRadiusXLarge,
                                    borderTopRightRadius: tokens.borderRadiusXLarge,
                                    borderBottomRightRadius: tokens.borderRadiusXLarge,
                                    borderBottomLeftRadius: tokens.borderRadiusXLarge,
                                   

                                    overflowY: "auto"
                                }}

                            >
                                <div
                                    style={{
                                        marginLeft: tokens.spacingHorizontalM,
                                        marginRight: tokens.spacingHorizontalM,
                                        marginTop: tokens.spacingVerticalS,
                                    }}

                                >
                                {selectedItem.note === null ?"" :selectedItem.note}
                                </div>
                            </div>

                        </div>


                    }

                    {selectedItem == null &&

                        <div
                            style={{
                                minHeight: "5vh",
                                maxHeight: "5vh",
                               
                                borderTopLeftRadius: tokens.borderRadiusXLarge,
                                borderTopRightRadius: tokens.borderRadiusXLarge,
                                borderBottomRightRadius: tokens.borderRadiusXLarge,
                                borderBottomLeftRadius: tokens.borderRadiusXLarge,


                                overflowY: "auto"
                            }}

                        >
                        </div>
                    
                    
                    }

                </div>
                
                <div
                    style={{ marginTop: tokens.spacingVerticalM }}
                >

                </div>





                {Footer()}

            </div>

        </CustomFluentProvider>

    )
}


