﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    
    TableRowId,
    DataGridProps,
    
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    Title2,
    
    Spinner,
    Toast,
    ToastTitle,
    useToastController,
    ToastTrigger,
    useId,
    Link,
    ButtonProps
} from "@fluentui/react-components";
import { AddRegular, CallConnectingRegular, DeleteRegular, Dismiss24Regular, PersonAccountsRegular, DismissRegular, EditRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { ICacheUserWithGroups, IUserProps, IUserShare } from "../entitiesAPI";
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { v4 as uuidv4 } from 'uuid';


import {
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { IDataBase, IDataSet, IDataSetColumn, PortalGroupSlim } from "../ddentities";
import { _copyAndSort, classNames, classNamesMessageBar, formatDate } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { DeleteDataSetSQLTable, AddSQLTableDataSet, GetSQlTables, GetAllSqlTableDataSets, GetDataBases,  UpdateDataSetSQlTable, UpdateDataSetAccess, getShareUsers } from "../../apiFunctions/baseFunctions/baseFunctions";
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { forEach } from "lodash";
import { LookUpTableGrid } from "./lookUpTableGrid";
import { AccessControl } from "../shared/AccessControl";
import { SqlTablesManage } from "./SqlTablesManage";
import { JSX } from "react/jsx-runtime";

var _allUsers: IUserShare[] = [];


export const SqlTables: React.FC = () => {

    const userObj: IUserProps = usePersistentStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;


    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();


    const [dataSets, setDataSets] = React.useState<IDataSet[]>([]);
    const [currentdataSet, SetCurrentDataSet] = React.useState<IDataSet>(null);
    const [hideDialog, setHideDialog] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [isOpenRename, setIsOpenRename] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isPopupVisible, setIsPopUpVisible] = React.useState(false);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [NewName, setNewName] = React.useState<string>("");
    const [connectionString, setConnectionString] = React.useState<string>("");
    const [database, setDatabase] = React.useState<string>("");
    const [table, setTable] = React.useState<string>("");
    const [schema, setSchema] = React.useState<string>("");
    const [newEditor, setNewEditor] = React.useState<boolean>(false);
    const containerRef = React.useRef(null);
    const [items, setItems] = React.useState<Item[]>([]);
    const [open, setOpen] = React.useState(false);
    const [dataSetid, setDataSetid] = React.useState<string>("");
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const inputColumnRef = React.useRef<HTMLInputElement>(null);
    const [accessIsOpen, setAccessIsOpen] = React.useState(false);
    const [fetchDatabases, setFetchDataBases] = React.useState<string[]>([]);
    const [fetchTables, setFetchTables] = React.useState<IDataBase[]>([]);
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const toasterId = useId("toaster45453453");
    const { dispatchToast } = useToastController(toasterId);
    const [isloading, setisloading] = React.useState(false); 
    const [connectionVerified, setConnectionVerified] = React.useState(false);

    


    var tmpConnectionString = "";   
   

    type NameCell = {
        label: string;
        id: string;
    };

    type LastUpdatedCell = {
        label: string;
        timestamp: number;

    }
    type LastUpdateBy = {
        label: string;
    }
    type EnabledData = {
        label: string;
        enabled: boolean;
    }

    type Item = {
        name: NameCell;
        lastUpdated: LastUpdatedCell;
        lastUpdateBy: LastUpdateBy;
        enabledDataSet: EnabledData;
    };
    const notifyError = () =>
        dispatchToast(
            <Toast>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>

                    }


                >{errorMsg}</ToastTitle>
            </Toast>,
            {  position:'top'  , intent: "error" }
        );


    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "name",

            compare: (a, b) => {
                return a.name.label.localeCompare(b.name.label);
            },
            renderHeaderCell: () => {
                return "Name";



            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div style={{ width: "500px" }}>{item.name.label}</div>

                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdated",
            compare: (a, b) => {
                return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            },
            renderHeaderCell: () => {
                return "Last updated";
            },

            renderCell: (item) => {
                return (
                    <TableCellLayout style={{ width: "200px" }} >
                        {item.lastUpdated.label}
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdateBy",
            compare: (a, b) => {
                return a.lastUpdateBy.label.localeCompare(b.lastUpdateBy.label);
            },
            renderHeaderCell: () => {
                return "Modified by";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.lastUpdateBy.label}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "enabledDataSet",
            //compare: (a, b) => {
            //    return a.enabledDataSet.enabled. localeCompare(b.enabledDataSet.enabled);
            //},
            renderHeaderCell: () => {
                return "Dataset enabled";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.enabledDataSet.enabled ? "Yes" : "No"}
                    </TableCellLayout>
                );
            },
        }),

    ]


    React.useEffect(() => {
        if (isOpen && inputColumnRef.current) {
            inputColumnRef.current.focus();
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpenRename && inputColumnRef.current) {
            inputColumnRef.current.focus();
            /* inputColumnRef.current.select();*/
        }
    }, [isOpenRename]);

  
    React.useEffect(() => {}, [dataSets]);

   
React.useEffect(() => {


    const run = async () => {

        if (isloading) {
            //dummy to avoid multiple calls
            setisloading(false);
            return;
        }

        if (connectionString.length === 0)
            return;

        setShowSpinner(true);
        let authObj = await getAuthObject();
        let result = await GetDataBases(authObj, connectionString);

        if (result.status === "OK" && !currentdataSet) {
            setFetchDataBases(result.dataBases.map(database => database.name));
            
        }
        else if (result.status === "OK" && currentdataSet) {
            var dbs = await GetDataBases(authObj, connectionString);
            var dbFound = dbs.dataBases.find(x => x.name === currentdataSet.database.name);  
            if (!dbFound) {
                setErrorMsg("Database not found on connection");
                return;
            }
            var _tables = await GetSQlTables(authObj, connectionString, currentdataSet.database.name);  
            var tableFound = _tables.tables.find(x => x.name === currentdataSet.tableInfo.name);   
            if (!tableFound) {
                setErrorMsg("Table not found on connection");
                return;
            }
            setShowSpinner(false);
            setConnectionVerified(true);
            return;
        }   
        else {
            setErrorMsg(result.status);
            notifyError();
        }
        setShowSpinner(false);
    }
    run();

},[connectionString]);


React.useEffect(() => {
    const run = async () => {
        setShowSpinner(true);
        let authObj = await getAuthObject();
        let result = await GetSQlTables(authObj, connectionString,database);

        if (result.status === "OK") {

            setFetchTables(result.tables);
        }
        else {
            setErrorMsg(result.status);
            notifyError();
        }
        setShowSpinner(false);
        if (!currentdataSet) 
            setConnectionVerified(true);
    }
    if (database.length == 0)
        return;

    run();

}, [database]);




    function refreshDataSets(force: boolean) {

        if (isOpenEdit && !force)
            return;

        getAuthObject().then((authResult) => {
            GetAllSqlTableDataSets(authResult).then((datasets) => {

                var tmpitems: Item[] = [];

                for (var i = 0; i < datasets.length; i++) {

                    var userEmail = "";
                    var formattedDate = "";

                    if (datasets[i].updated != undefined) {
                        let user = _allUsers.find(x => x.id === datasets[i].dataUpdatedBy)
                        userEmail = (user === undefined ? "n/a" : user.email);
                    }

                    try {
                        formattedDate = new Date(datasets[i].dataUpdated).toLocaleString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'

                        });

                    } catch (e) {
                        formattedDate = e.toString();

                    }

                    var tmp: Item = {
                        name: { label: datasets[i].name, id: datasets[i].id },
                        enabledDataSet: { label: datasets[i].enabled ? "Yes" : "No", enabled: datasets[i].enabled },
                        lastUpdateBy: { label: userEmail },
                        lastUpdated: { label: formattedDate, timestamp: new Date(datasets[i].dataUpdated).getTime() }
                    }

                    tmpitems.push(tmp);
                }
                setItems(tmpitems);
                setDataSets(datasets);
            });

        });
    }
    const refreshusers = () => {

        return new Promise(resolve => {

            if (window.MyshareUsers != undefined) {

                var portalusers = window.MyshareUsers;
                var currentUser = JSON.parse(sessionStorage.getItem("user"));

                if (currentUser !== null && currentUser !== undefined) {
                    var u: IUserShare = {
                        email: currentUser.portalUser.Description.email,
                        id: currentUser.portalUser.id,
                        name: currentUser.portalUser.Description.name
                    };
                    portalusers.push(u);
                }
                _allUsers = portalusers;
                resolve("Ok");

            }
            else {

                getAuthObject().then((authResult) => {
                    getShareUsers(authResult).then((usersResult) => {

                        let pl: IUserShare[] = [];
                        pl = usersResult;
                        //_allUsers = pl;
                        window.MyshareUsers = pl;

                        var portalusers = pl;
                        var currentUser = JSON.parse(sessionStorage.getItem("user"));

                        if (currentUser !== null && currentUser !== undefined) {
                            var u: IUserShare = {
                                email: currentUser.portalUser.Description.email,
                                id: currentUser.portalUser.id,
                                name: currentUser.portalUser.Description.name
                            };
                            portalusers.push(u);
                        }
                        _allUsers = portalusers;
                        resolve("Ok");
                    });

                });
            }
        })
    }

    React.useEffect(() => {
        refreshusers().then(() => {
            refreshDataSets(false);

        });






    }, []);

    const DeleteSelectedDataSets = () => {
        return getAuthObject().then((authResult) => {
            var dataSetIdArray = Array.from(selectedRows);
            var deletePromises = dataSetIdArray.map(dataSetId => DeleteDataSetSQLTable(authResult, dataSetId.toString()));
            return Promise.all(deletePromises);
        });
    }

  
    const AddNewDataSet = (name: string) => {
        getAuthObject().then((authResult) => {
            AddSQLTableDataSet(authResult, name,connectionString,database,table, schema).then((dataset) => {
                refreshDataSets(false);
                SetCurrentDataSet(dataset);

                setIsOpen(false);
                setIsPopUpVisible(true);
                //SetAddColumnOpen(true);
            });


        });
    }

    const updateDataSetName = (dataSetId: string, newName: string) => {
        const updatedDataSets = dataSets.map((dataSet) => {
            if (dataSet.id === dataSetId) {
                return { ...dataSet, name: newName };
            }
            return dataSet;
        });
        setDataSets(updatedDataSets);
    };

    const editDataSet = (dataSet: IDataSet) => {
        getAuthObject().then((authResult) => {
            UpdateDataSetSQlTable(authResult, dataSet).then((datasetResult) => {
                /*refreshDataSets(false);*/
                setIsOpenRename(false);
                refreshDataSets(true);  
            });

        });


    }
  

   

    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );
    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
    };

    function closeGrid(closeit: boolean) {
        if (closeit)
            setIsOpenEdit(false);
    }

    function forceRefresh() {
        refreshDataSets(true);
    }


    function updateAccess(usersToAdd: ICacheUserWithGroups[], usersToRemove: ICacheUserWithGroups[], groupsToAdd: PortalGroupSlim[], groupsToRemove: PortalGroupSlim[]) {

        async function _update() {

            var authObj = await getAuthObject();
            var dataSet = await UpdateDataSetAccess(authObj, currentdataSet.id, usersToAdd.map(user => user.id), usersToRemove.map(user => user.id), groupsToAdd.map(grp => grp.id), groupsToRemove.map(grp => grp.id))
            let updatedDataSets = dataSets.map(ds => ds.id === dataSet.id ? dataSet : ds);
            setDataSets(updatedDataSets);
            setAccessIsOpen(false);
        }

        _update();

    }

    function closeAccess() {
        setAccessIsOpen(false);
    }
   
    const ConnectButton: React.FC<ButtonProps> = (props) => {
        return (
            <Button
                {...props}
                appearance="transparent"
                icon={<CallConnectingRegular />}
                size="small"
                onClick={() => {

                    if (tmpConnectionString.length === 0)
                    {
                        tmpConnectionString = currentdataSet.connectionString;
                    }
                        setConnectionString(tmpConnectionString);

                }
                    
                }
            />
        );
    };


    function disableOk() {

        if (currentdataSet) {
            if (NewName !== currentdataSet.name)
                return false;
            if (connectionString !== currentdataSet.connectionString && connectionVerified)
                return false;
            return true;
        } 

        if (NewName.length === 0)
            return true;
        if (connectionString.length === 0)
            return true;
        if (database.length === 0)
            return true;
        if (table.length === 0)
            return true;
        if (connectionVerified === false)
            return true;

        return false;
        
    }



    return (
        <div>
            <div
                style={{
                    marginTop: tokens.spacingVerticalNone,
                    marginBottom: tokens.spacingVerticalXL,
                    marginLeft: "20px",
                    maxWidth: "100%"

                }}
            >
                <Title2>Azure SQL tables</Title2>
            </div>


            {isOpenEdit && <div>  <SqlTablesManage closeGrid={closeGrid} dataSet={currentdataSet} refresh={forceRefresh}  ></SqlTablesManage></div>}


            {!open &&

                <div
                    style={{

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,

                        width: "80dvw"


                    }}>
                    <Toolbar aria-label="Vertical Button" >
                        <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {
                            setNewName("");
                            tmpConnectionString = "";
                            setConnectionString("");
                            setDatabase("");
                            setTable("");
                            setSchema("");
                            setConnectionVerified(false);
                            setIsOpen(true);
                            SetCurrentDataSet(null);

                        }}>New

                        </ToolbarButton>
                        <ToolbarButton icon={<EditRegular></EditRegular>} disabled={selectedRows.size !== 1} onClick={() => {

                            var dataSetIdArray = Array.from(selectedRows);
                            var dataSetId = dataSetIdArray[0];
                            var dataset = dataSets.find(x => x.id === dataSetId.toString());
                            SetCurrentDataSet(dataset);
                            setIsOpenEdit(true);


                        }}>Configure columns
                        </ToolbarButton>
                        <ToolbarButton icon={<RenameRegular></RenameRegular>} disabled={selectedRows.size !== 1} onClick={() => {
                            setisloading(true);
                            setConnectionVerified(false);   
                            var dataSetIdArray = Array.from(selectedRows);
                            var dataSetId = dataSetIdArray[0];
                            var dataset = dataSets.find(x => x.id === dataSetId.toString());
                            SetCurrentDataSet(dataset);
                            setNewName(dataset.name);
                            
                            setDatabase(dataset.database.name);
                            setTable(dataset.tableInfo.name);
                            setSchema(dataset.tableInfo.schema);
                            setConnectionString(dataset.connectionString);  
                            setIsOpen(true);
                            

                        }} >Change connection
                        </ToolbarButton>


                        <ToolbarButton icon={<DeleteRegular></DeleteRegular>} disabled={selectedRows.size === 0} onClick={() => {
                            setIsOpenDelete(true);

                        }}>
                            Delete
                        </ToolbarButton>

                        {userObj.permissions.findIndex(x => x == "Access management for data manager") > -1 &&
                            <ToolbarButton icon={<PersonAccountsRegular />} disabled={selectedRows.size === 0} onClick={() => {

                                var dataSetIdArray = Array.from(selectedRows);
                                var dataSetId = dataSetIdArray[0];
                                var dataset = dataSets.find(x => x.id === dataSetId.toString());
                                SetCurrentDataSet(dataset);
                                setAccessIsOpen(true);

                            }}>
                                Access
                            </ToolbarButton>}



                    </Toolbar></div>}
            {!open &&
                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,

                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,

                        height: "69dvh",
                        width: "80dvw"


                    }}>


                    <DataGrid
                        items={items}
                        columns={columns}
                        sortable
                        selectionMode="multiselect"

                        getRowId={(item) => item.name.id}
                        focusMode="composite"
                        size="medium"
                        selectedItems={selectedRows}
                        onSelectionChange={onSelectionChange}
                        style={{ overflow: "auto" }}

                    >
                        <DataGridHeader>
                            <DataGridRow
                                selectionCell={{
                                    checkboxIndicator: { "aria-label": "Select all rows" },
                                }}
                            >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}




                            </DataGridRow>

                        </DataGridHeader>
                        <DataGridBody<Item>>
                            {({ item, rowId }) => (
                                <DataGridRow<Item>
                                    key={rowId}
                                    selectionCell={{
                                        checkboxIndicator: { "aria-label": "Select row" },
                                    }}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid></div>
            }


            <Dialog open={isOpen}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setErrorMsg("");
                                        setIsOpen(false);
                                    }}
                                />
                            }
                        >
                            {currentdataSet?"Edit connection":"Add new dataset"}
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                <Input autoFocus={isOpen} defaultValue={NewName} onChange={(e, v) => { setNewName(e.currentTarget.value); }} ref={inputColumnRef} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Connectionstring
                                </Label>
                                <Input defaultValue={connectionString}
                                    onChange={(e, v) => {
                                        tmpConnectionString = e.currentTarget.value;   
                                        
                                    }}

                                    onBlur={(e) => {
                                        tmpConnectionString = e.currentTarget.value;

                                    }}
                                    contentAfter={<ConnectButton/>}
                                     />
                                

                            </div>


                            {connectionString.length > 0 && (fetchDatabases.length>0 || database.length>0) && <div style={{ display: "flex", flexDirection: "column" }}>
                            
                             

                                <Label>
                                    Select Database
                                </Label>
                                <Dropdown
                                    placeholder="Select database"
                                    defaultValue={database}
                                  
                                    disabled={currentdataSet?true:false}
                                >
                                {fetchDatabases.map((database) => (
                                    <Option key={database} value={database}
                                        onClick={(v) => {
                                          
                                            
                                            setDatabase(v.currentTarget.innerText)
                                    }}>
                                            {database}
                                        </Option>
                                    ))}
                                    </Dropdown>
                            </div>}
                            {connectionString.length > 0 && database.length > 0 && (setFetchTables.length > 0|| table.length>0) && <div style={{ display: "flex", flexDirection: "column" }}>



                                <Label>
                                    Select Table or View
                                </Label>
                                <Dropdown
                                    placeholder="Select table or view'"
                                    defaultValue={table}
                                  
                                    disabled={currentdataSet?true:false}

                                >
                                    {fetchTables.map((table) => (
                                        <Option key={table.name} value={table.name}
                                            onClick={(v) => {

                                                setSchema(table.schema);
                                                setTable(v.currentTarget.innerText);
                                            }}
                                        >
                                            {table.name}
                                        </Option>
                                    ))}
                                </Dropdown>
                            </div>
                            }

                            {showSpinner && <Spinner size="extra-tiny" label="Fetching data..." />}
                            {errorMsg.length > 0 && <div style={{ color: "red" }}>{errorMsg}</div> }


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setErrorMsg("");
                                setIsOpen(false);
                                
                            }} >Close</Button>
                             
                            
                            <Button appearance="primary" disabled={disableOk()}
                                onClick={async () =>  {
                                    if (!currentdataSet) { 
                                        SetCurrentDataSet(null);
                                        AddNewDataSet(NewName);
                                        setNewName("");
                                        setIsOpen(false);
                                    }
                                    else {
                                        currentdataSet.connectionString = connectionString;
                                        currentdataSet.name = NewName;  
                                        await editDataSet(currentdataSet);
                                        setIsOpen(false);
                                    }
                                    
                                }}
                            >
                                Ok
                            </Button>


                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenRename}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenRename(false);
                                    }}
                                />
                            }
                        >
                            Rename dataset
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                <Input defaultValue={NewName} autoFocus={isOpenRename} onChange={(e, v) => { setNewName(e.currentTarget.value); }} ref={inputColumnRef} />
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setIsOpenRename(false);

                            }} >Close</Button>



                            <Button appearance="primary" disabled={NewName.length === 0}
                                onClick={() => {
                                    currentdataSet.name = NewName;
                                    editDataSet(currentdataSet);

                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenDelete}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenDelete(false);
                                    }}
                                />
                            }
                        >
                            Delete?
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                Please confirm to delete {selectedRows.size} {selectedRows.size === 1 ? "dataset" : "datasets"}, when deleted the datasets can not be restored.
                            </div>

                        </DialogContent>
                        <DialogActions>


                            <Button onClick={() => {
                                setIsOpenDelete(false);

                            }} >Close</Button>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    DeleteSelectedDataSets().then(() => {
                                        refreshDataSets(false);
                                        setIsOpenDelete(false);
                                        setSelectedRows(new Set<TableRowId>([]));

                                    })

                                }}
                            >
                                Delete {selectedRows.size} {selectedRows.size === 1 ? "dataset" : "datasets"}
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {accessIsOpen && <AccessControl close={closeAccess} users={currentdataSet.usersWithAccess} groups={currentdataSet.groupsWithAccess} update={updateAccess}  ></AccessControl>}








            <div
                style={{
                    height: "33px",
                    width: "80dvw",
                    backgroundColor: tokens.colorNeutralBackground1,
                    borderBottomRightRadius: tokens.borderRadiusXLarge,
                    borderBottomLeftRadius: tokens.borderRadiusXLarge,
                    marginLeft: tokens.spacingHorizontalM,
                }}
            />
        </div>


    )


}



function dispatchToast(arg0: JSX.Element, arg1: { position: string; intent: string; }) {
    throw new Error("Function not implemented.");
}
