import { FC, useState, useEffect } from "react";
import { ClausesView } from "documentdrafter-components";
import { useNavigate } from "react-router-dom";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";

import { SearchDirectory, GetWorkSpaceById } from "documentdrafter-components";
import { ISlimCacheSearchResult, ISlimCachedDirEntry, IEntryType, ICachePath } from "documentdrafter-components"
import { IMainState } from "../ddentities";
import React from "react";


let searchTimer: number;

interface IClausesTab {
    searchValue: string;
    setSearchValue: (newValue: string) => void;
    selectedWorkspace: string;
    setSelectedWorkspace: (id: string) => void;
    isFetching: boolean;
    setIsFetching: (value: boolean) => void;
    screenSize: number;
    setShowSearchbar: (show: boolean) => void;
    forceGoToRoot: boolean;
}

export const ClausesTab: FC<IClausesTab> = (props) => {
    const [activeFolder, setActiveFolder] = useState<string>("");
    const [searchView, setSearchView] = useState<ISlimCacheSearchResult[]>([]);
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const userObj = usePersistentStore().userObj;
    const directory = useStore().workspaceDirectory;
    const workspaces = useStore().availableWorkspaces;
    const templateColumnsResizeValues = usePersistentStore().templateColumnsResizeValues;
    const navigate = useNavigate();

    useEffect(() => {
        const previousMainState: IMainState = JSON.parse(sessionStorage.getItem("previousMainState"));
        if (previousMainState && previousMainState.activeTab === "clauses" && previousMainState.openItem.length) {
            setActiveFolder(previousMainState.openItem);
            props.setShowSearchbar(false);
            //timeout because this might be hit a few times upon initialization
            setTimeout(() => { sessionStorage.removeItem("previousMainState"); }, 1000);
            
        }
        else if (props.selectedWorkspace) {
            setActiveFolder(props.selectedWorkspace);
            props.setShowSearchbar(true);
        }
    }, [props.selectedWorkspace, props.forceGoToRoot]);

    useEffect(() => {
        clearTimeout(searchTimer);

        if (props.searchValue.length) {
            searchTimer = setTimeout(() => {
                props.setIsFetching(true);

                SearchDirectory(userObj!, props.searchValue, false, true).then((searchResult) => {
                    setHasSearched(true);
                    setSearchView(searchResult);

                    props.setIsFetching(false);
                });
            }, 250);
        } else {
            setHasSearched(false);
            setSearchView([]);
        }
    }, [props.searchValue]);

    function setTemplateColumnsResizeValues(columnId: string, width: number) {
        const resizeData = { ...templateColumnsResizeValues };
        const columnData = resizeData[columnId];

        if (columnData) {
            if (columnData.minWidth && width < columnData.minWidth) {
                width = columnData.minWidth;
            }

            columnData.defaultWidth = width;
            columnData.idealWidth = width;
        }
        usePersistentStore.setState({ documentColumnsResizeValues: resizeData });
    }

    function onSearchBreadcrumbClick(pathItem: ICachePath, workspace: ICachePath) {
        //check ff_ first. in the clause structure templates are turned into folders with "ff_" prepended
        //to avoid multiple identical ids
        let dirEntry = directory.clauses.find((x) => x.id === "ff_" + pathItem.id);
        if (!dirEntry) dirEntry = directory.clauses.find((x) => x.id === pathItem.id);

        //dirEntry is inside current workspace
        if (dirEntry) {
            setActiveFolder(dirEntry.id);
        }
        //navigating to current workspace
        else if (pathItem.id === props.selectedWorkspace) {
            setActiveFolder("");
            props.setShowSearchbar(true);
        }
        //navigating to another workspace
        else {
            GetWorkSpaceById(userObj!, workspace.id).then((workspaceResult) => {
                useStore.setState({ workspaceDirectory: workspaceResult });
                props.setSelectedWorkspace(workspace.id);

                let dirEntry2 = workspaceResult.clauses.find((x) => x.id === "ff_" + pathItem.id);
                if (!dirEntry2) dirEntry2 = workspaceResult.clauses.find((x) => x.id === pathItem.id);

                if (dirEntry2) {
                    setTimeout(() => {
                        setActiveFolder(dirEntry2.id);
                        props.setShowSearchbar(dirEntry2.id === workspace.id);
                    }, 250);
                }
            });
        }
    }

    function getDirectoryView() {
        let directoryEntries: ISlimCachedDirEntry[] = [];

        if (props.searchValue && props.searchValue.length && searchView.length) {
            directoryEntries = searchView;

            // for (let i = 0; i < directory.templates.length; i++) {
            //     if (directoryEntries.length > 99) break;
            //     const dir = directory.templates[i];
            //     if (dir.name?.toLowerCase().indexOf(props.searchValue.toLowerCase()) > -1) directoryEntries.push(dir);
            // }
        } else if (props.searchValue && props.searchValue.length && !searchView.length) {
            directoryEntries = [];
        } else {
            directoryEntries = directory.clauses.filter(
                (x) => x.parentid === activeFolder
            );
        }

        return directoryEntries;
    }

    function onDirEntryClick(item: ISlimCachedDirEntry) {
        if (item.entrytype === 0) {
            setActiveFolder(item.id);
            props.setShowSearchbar(item.id === props.selectedWorkspace);
        } else if (item.entrytype === 3) {
            return "download";
        } else {

            const mainState: IMainState = { activeTab: "clauses", openItem: item.parentid }
            //if search is active, we dont navigate to item
            if (props.searchValue.length)
                mainState.openItem = "";
            sessionStorage.setItem("previousMainState", JSON.stringify(mainState));

            navigate(`/document/clause/${item.id}/${item.docid}/${item.clauseid}`);
        }
    }

    const breadcrumbItems: ISlimCachedDirEntry[] = [];

    let parentId = activeFolder;

    while (parentId) {
        const dir = directory.clauses.find((x) => x.id === parentId);

        if (!dir) break;

        breadcrumbItems.push(dir);

        parentId = dir!.parentid;
    }

    const workspace = workspaces.find((x) => x.id === props.selectedWorkspace);
    if (workspace) {
        breadcrumbItems.push({
            id: workspace.id,
            name: workspace.name,
            parentid: "",
            clauseid: "",
            docid: "",
            entrytype: IEntryType.Folder,
        });
    }
    breadcrumbItems.reverse();

    return (
        <ClausesView
            directories={getDirectoryView()}
            currentPath={breadcrumbItems}
            onDirEntryClick={onDirEntryClick}
            onSearchBreadcrumbClick={onSearchBreadcrumbClick}
            setSearchValue={props.setSearchValue}
            templateColumnsResizeValues={templateColumnsResizeValues}
            setTemplateColumnsResizeValues={setTemplateColumnsResizeValues}
            isFetching={props.isFetching}
            screenSize={props.screenSize}
            hasSearched={hasSearched}
        />
    );
};
