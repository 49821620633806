import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    tokens,
} from "@fluentui/react-components";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { NavigationRegular, QuestionCircleRegular, SignOutRegular } from "@fluentui/react-icons";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { LeftPaneNav } from "documentdrafter-components";
import { GetWorkSpaceById, GetWorkspaces } from "documentdrafter-components";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { TemplatesTab } from "./tabTemplates";
import { DocumentsTab } from "./tabDocuments";
import { WorkspacesTab } from "./tabWorkspaces";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider, BottomNavMobile, MobileHeader } from "documentdrafter-components";
import { ClausesTab } from "./tabClauses";
import { getCustomTheme, HelpPanel } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory, ICacheUser, getShareUsers, BulkGeneration } from "documentdrafter-components";
import { GetMailTemplate } from "../../apiFunctions/baseFunctions/adminFunctions";
import { getHelpPortalUrl } from "../../apiFunctions/baseFunctions/baseFunctions";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps } from "../entitiesAPI";
import { IMainState, LinksPageTypes } from "../ddentities";

//used from within the signalR events
let selectedTabOutOfState: string = "";
let workspaceDirectoryOutOfState: ISlimCachedDirectory = { templates: [], clauses: [] }
let signalRGetWorkspacesTimer: NodeJS.Timeout | undefined = undefined;
let selectedWorkspaceOutOfState: string = "";
let recentWorkspacesOutOfState: string[] = [];

export function b64DecodeUnicode(str): string {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
}
interface IMainPage {
    workspacePreview: string;
}

export const MainPage: React.FC<IMainPage> = (props) => {
    //MainPage component should access global values from Zustand instead of props
    //Then pass values from Zustand as props to single components on the page
    const [isFetching, setIsFetching] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string>(() => {
        let initialTab = "templates";
        const previousTab: IMainState = JSON.parse(sessionStorage.getItem("previousMainState"));
        if (previousTab) {
            //sessionStorage.removeItem("previousTab");
            initialTab = previousTab.activeTab;
        }

        return initialTab;
    });
    const [forceGoToRoot, setForceGoToRoot] = useState(false);
    const [hasClauses, setHasClauses] = useState<boolean>(false);
    useEffect(() => {
        selectedTabOutOfState = selectedTab;
    }, [selectedTab]);

    const [selectedWorkspace, setSelectedWorkspace] = useState<string>("");
    useEffect(() => {
        selectedWorkspaceOutOfState = selectedWorkspace;
    }, [selectedWorkspace]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [showSearchbar, setShowSearchbar] = useState<boolean>(true);

    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);

    const [shareUsers, setShareUsers] = useState<ICacheUser[]>([]);
    const [showHelpPanel, setShowHelpPanel] = useState<boolean>(false);
    const [helpText, setHelpText] = useState<{ title: string, body: string }>({ title: "", body: "" });
    const [helpUrl, setHelpUrl] = useState<string>("");


    const userObj: IUserProps = usePersistentStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    const workspaces = useStore().availableWorkspaces;
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;
    const portalLinks = useStore().portalLinks;
    const endPoints = useStore().endpoints;
    const recentWorkspaces = usePersistentStore().recentWorkspaces;
    useEffect(() => {
        recentWorkspacesOutOfState = recentWorkspaces;
    }, [recentWorkspaces]);
    const workspaceDirectory = useStore().workspaceDirectory;
    useEffect(() => { workspaceDirectoryOutOfState = workspaceDirectory }, [workspaceDirectory]);

    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();
    //const signalRUpdateObj = useStore().signalRUpdate;

    const customTokens = themeToTokensObject(getCustomTheme(!isDark));

    useEffect(() => {

        setEndpoints(endPoints);
        if (!isUserAuthenticated) {
            const currentpath = window.location.pathname + window.location.search;

            if (!userObj) navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            else {
                navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            }
        } else {
            if (!selectedWorkspace && recentWorkspaces.length) {
                const newRecentWorkspaces = recentWorkspaces.filter(
                    (x) => workspaces.findIndex((y) => y.id === x) > -1,
                );
                if (newRecentWorkspaces.length) changeWorkspace(newRecentWorkspaces[0], false, true);
                usePersistentStore.setState({ recentWorkspaces: newRecentWorkspaces });
                //changeWorkspace(recentWorkspaces[0]);
            }

            if (userObj) {
                if (
                    userObj.permissions.includes("Files management") ||
                    userObj.permissions.includes("Access to data manager") ||
                    userObj.permissions.includes("Download drafter addin") ||
                    userObj.permissions.includes("Style configuration") ||
                    userObj.permissions.includes("Security Config") ||
                    userObj.permissions.includes("System configuration") ||
                    userObj.permissions.includes("Reports") ||
                    userObj.permissions.includes("Security Config")
                ) {
                    getHelpPortalUrl().then((helpUrlResult) => {
                        setHelpUrl(helpUrlResult);
                    });
                }
                if (userObj.isPublic) {
                    window.location.href = portalConfig.publicQuestionnaireReturnLink
                    return;
                }




                GetMailTemplate(userObj!, "h.mainleft").then((data) => {

                    let helptxt = data;
                    var help = helptxt;
                    help.htmlbody = b64DecodeUnicode(helptxt.htmlbody);

                    setHelpText({ title: help.name, body: help.htmlbody });

                }).catch((error) => {
                    console.log('Inithelp Catch:', error);
                });

                GetWorkspaces(userObj).then((workspacesResult) => {
                    useStore.setState({ availableWorkspaces: workspacesResult });
                });
            }
            fetchShareUsers();
        }

        if (props.workspacePreview) {
            const channel = new BroadcastChannel('workspacePreview_channel');


            channel.addEventListener('message', event => {
                if (event.data === 'refresh') {

                    if (props.workspacePreview === selectedWorkspaceOutOfState) {

                        changeWorkspace(selectedWorkspaceOutOfState, true);
                    }
                }
            });

            changeWorkspace(props.workspacePreview);
        }

    }, []);

    useEffect(() => {
        if (screenSize === ScreenSize.mobile && selectedTab === "bulkgeneration") {
            ChangeActiveTab("templates");
        }
    }, [screenSize]);

    //useEffect(() => {
    //    if (signalRUpdateObj.dirEntriesUpdated.length) {
    //        //SetTimeout eliminates a issue where getWOrkspacebyid() is not called, even though the values in signalRUpdateObj.dirEntriesUpdated is correct
    //        setTimeout(() => {
    //            for (let i = 0; i < signalRUpdateObj.dirEntriesUpdated.length; i++) {
    //                const modified = signalRUpdateObj.dirEntriesUpdated[i];

    //                if (
    //                    modified.indexOf("ALL") > -1 ||
    //                    workspaceDirectory.templates.findIndex((x) => x.id === modified || x.parentid === modified) >
    //                    -1 ||
    //                    (recentWorkspaces.length && recentWorkspaces[0] === modified)
    //                ) {
    //                    setIsFetching(true);

    //                    GetWorkSpaceById(userObj!, recentWorkspaces[0]).then((workspaceContent) => {
    //                        useStore.setState({ workspaceDirectory: workspaceContent });
    //                        setIsFetching(false);
    //                    });
    //                    break;
    //                }
    //            }
    //        }, 1000);
    //    }
    //}, [signalRUpdateObj.dirEntriesUpdated]);

    //if recentworkspaces is empty we set workspaces.sort()[0].id as the active workspace
    useEffect(() => {
        if (workspaces && workspaces.length) {
            if (!recentWorkspaces.length) {
                changeWorkspace(workspaces.sort()[0].id);
            }
        }
    }, [recentWorkspaces, workspaces]);

    function fetchShareUsers() {
        getShareUsers(userObj!).then((result) => {
            setShareUsers(result);
        });
    }
    function addRecentWorkspace(workspaceId: string) {
        const recentIds = [...recentWorkspaces];
        const workspaceIndex = recentIds.indexOf(workspaceId);

        if (workspaceIndex > -1) {
            recentIds.splice(workspaceIndex, 1);
        } else if (recentIds.length > 9) {
            recentIds.pop();
        }
        recentIds.unshift(workspaceId);

        usePersistentStore.setState({ recentWorkspaces: recentIds });
    }

    function doSignalRUpdate(update: ISignalRUpdate) {
        if (update.type === SignalRUpdateType.RefreshWorkspace) {
            console.log("Refreshing workspace directory");
            GetWorkspaces(userObj!).then((workspacesResult) => {
                let currentWorkspaceRemoved: boolean = false;
                if (workspacesResult.findIndex((x) => x.id === selectedWorkspaceOutOfState) === -1) {
                    for (let i = 0; i < recentWorkspacesOutOfState.length; i++) {
                        if (workspacesResult.findIndex((x) => x.id === recentWorkspacesOutOfState[i]) > -1) {
                            changeWorkspace(recentWorkspacesOutOfState[i]);
                            currentWorkspaceRemoved = true;
                            break;
                        }
                    }
                }
                useStore.setState({ availableWorkspaces: workspacesResult });

                if (!currentWorkspaceRemoved) {
                    for (let i = 0; i < update.entryIds.length; i++) {
                        const modified = update.entryIds[i];
                        //if the modified entry is in the current workspace | modified === currentworkspace, refresh the workspace
                        //If a file is added to the current workspace we dont know the file-id. So we refresh the entire workspace everytime
                        //TODO: find a better sollution
                        if (
                            modified === "ALL" ||
                            workspaceDirectoryOutOfState.templates.findIndex(
                                (x) => x.id === modified || x.parentid === modified,
                            ) > -1 ||
                            selectedWorkspaceOutOfState === modified
                        ) {
                            GetWorkSpaceById(userObj!, recentWorkspacesOutOfState[0], props.workspacePreview).then((workspaceContent) => {
                                setHasClauses(workspaceContent.clauses.length > 0);
                                useStore.setState({ workspaceDirectory: workspaceContent });
                                //setIsFetching(false);
                            });
                            break;
                        }
                    }
                }
            });
        }
        //if (update.type === SignalRUpdateType.RefreshDirEntry) {
        //    clearTimeout(signalRGetWorkspacesTimer);
        //    signalRGetWorkspacesTimer = setTimeout(() => {
        //        GetWorkspaces(userObj!).then((workspacesResult) => {
        //            let currentWorkspaceRemoved: boolean = false;
        //            if (workspacesResult.findIndex((x) => x.id === selectedWorkspaceOutOfState) === -1) {
        //                for (let i = 0; i < recentWorkspaces.length; i++) {
        //                    if (workspacesResult.findIndex((x) => x.id === recentWorkspaces[i]) > -1) {
        //                        changeWorkspace(recentWorkspaces[i]);
        //                        currentWorkspaceRemoved = true;
        //                        break;
        //                    }
        //                }
        //            }
        //            useStore.setState({ availableWorkspaces: workspacesResult });

        //            if (!currentWorkspaceRemoved) {
        //                for (let i = 0; i < update.entryIds.length; i++) {
        //                    const modified = update.entryIds[i];
        //                    //if the modified entry is in the current workspace | modified === currentworkspace, refresh the workspace
        //                    //If a file is added to the current workspace we dont know the file-id. So we refresh the entire workspace everytime
        //                    //TODO: find a better sollution
        //                    if (
        //                        modified === "ALL" ||
        //                        workspaceDirectoryOutOfState.templates.findIndex(
        //                            (x) => x.id === modified || x.parentid === modified,
        //                        ) > -1 ||
        //                        selectedWorkspaceOutOfState === modified
        //                    ) {
        //                        GetWorkSpaceById(userObj!, recentWorkspaces[0]).then((workspaceContent) => {
        //                            useStore.setState({ workspaceDirectory: workspaceContent });
        //                            //setIsFetching(false);
        //                        });
        //                        break;
        //                    }
        //                }
        //            }
        //        });
        //    }, 20000);
        //}
        else if (update.type === SignalRUpdateType.RefreshMyDocs) {

            if (selectedTabOutOfState === "mydocuments") {
                setDocumentsSignalRUpdateQueue((prevQueue) => [...prevQueue, update]);
            }
        }
        else if (update.type === SignalRUpdateType.RefreshUsers) {
            fetchShareUsers();
        }
    }

    function changeWorkspace(workspaceId: string, silent: boolean = false, blockTabChange: boolean = false) {
        if (!silent) {
            setSelectedWorkspace(workspaceId);
            addRecentWorkspace(workspaceId);

            if (!blockTabChange && selectedTab !== "clauses") ChangeActiveTab("templates");
        }
        setIsFetching(true);

        GetWorkSpaceById(userObj!, workspaceId, props.workspacePreview).then((workspaceContent) => {
            setHasClauses(workspaceContent.clauses.length > 0);
            useStore.setState({ workspaceDirectory: workspaceContent });
            if (selectedTab === "clauses" && workspaceContent.clauses.length < 1)
                ChangeActiveTab("templates");

            if (!silent)
                setForceGoToRoot((prev) => !prev);

            setIsFetching(false);
        });
    }

    function onSearchChange(value: string) {
        setSearchValue(value);
    }

    function ChangeActiveTab(newTab: string) {
        onSearchChange("");

        if (selectedTab === newTab) setForceGoToRoot((prev) => !prev);
        else setSelectedTab(newTab);
    }

    function getLeftpaneWorkspaces(getRecent: boolean) {
        const recentIds = [...recentWorkspaces];
        const other: ISlimWorkspace[] = [...workspaces];
        const recent: ISlimWorkspace[] = [];

        for (let i = 0; i < recentIds.length; i++) {
            const dirIndex = other.findIndex((x) => x.id === recentIds[i]);

            if (dirIndex > -1) recent.push(other.splice(dirIndex, 1)[0]);
            else {
                recentIds.splice(i, 1);
                i--;
            }
        }

        if (getRecent) return recent;

        return other.sort();
    }

    function getHeaderLogo(isMobile: boolean = false): JSX.Element[] {
        const elements: JSX.Element[] = [];

        let style: React.CSSProperties = screenSize === ScreenSize.mobile ? { maxHeight: "100%", maxWidth: "100%", objectFit: "contain", marginBottom: isMobile ? "2px" : "" }
            : { height: "100%" }

        if (!isDark) {
            if (portalConfig?.headerLogoPath)
                elements.push(
                    <img
                        key="logo"
                        style={style}
                        src={portalConfig.headerLogoPath}
                        alt=""

                    />
                );
        } else {
            if (portalConfig?.headerLogoPathDark)
                elements.push(
                    <img
                        key="logo"
                        style={style}
                        src={portalConfig.headerLogoPathDark}
                        alt=""

                    />
                );
        }

        return elements;
    }

    function getBurgerItems() {
        const items: string[] = [];
        if (!userObj)
            return items;

        if (userObj.permissions.indexOf("Files management") > -1) {
            items.push("File manager");
        }
        if (userObj.permissions.indexOf("Access to data manager") > -1) {
            items.push("Data manager");
        }
        if (userObj.permissions.indexOf("Users") > -1) {
            items.push("User manager");
        }

        if (userObj.permissions.indexOf("Word Plugin") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Download drafter addin") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Portal Config") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("System configuration") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Security Config") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Reports") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Style Config") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Style configuration") > -1) {
            items.push("Administration");
        }

        return items;
    }

    let headerHeight = "48px";
    if (portalConfig?.headerHeight?.length) {
        headerHeight = portalConfig?.headerHeight;
        if (portalConfig?.headerHeight.indexOf("px") < 0) headerHeight += "px";
    }
    let headerBottomPadding = "0px";
    if (portalConfig?.headerBottomPadding?.length) {
        headerBottomPadding = portalConfig.headerBottomPadding;
        if (portalConfig.headerBottomPadding.indexOf("px") < 0) headerBottomPadding += "px";
    }

    return (
        <MainPageSignalRWrapper userObj={userObj} doUpdate={doSignalRUpdate}>
            <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
                <>
                    <HelpPanel
                        isOpen={showHelpPanel}
                        header={helpText.title}
                        body={helpText.body}
                        setIsOpen={setShowHelpPanel}
                    />
                    {screenSize !== ScreenSize.mobile ? (


                        <div
                            style={{
                                height: "100dvh",
                                width: "100vw",
                                background:
                                    screenSize !== ScreenSize.mobile
                                        ? `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`
                                        : tokens.colorNeutralBackground1,
                            }}
                        >

                            <Header
                                searchValue={searchValue}
                                onSearchChange={onSearchChange}
                                leftSideItems={getHeaderLogo()}
                                rightSideItems={[
                                    <>
                                        {getBurgerItems().length > 0 &&
                                            <CustomMenuButton
                                                icon={<NavigationRegular />}
                                                key="burger"
                                                items={getBurgerItems()}
                                                onSelectMenuItem={(item: string) => {
                                                    if (item === "User manager") document.location.href = "/usermanager";
                                                    if (item === "File manager") document.location.href = "/filemanager";
                                                    if (item === "Administration") document.location.href = "/admin";
                                                    if (item === "Data manager") document.location.href = "/datamanager";
                                                }}
                                            />
                                        }
                                    </>,
                                    <ToolbarButton
                                        style={{
                                            height: "48px",
                                            width: "48px",
                                            maxWidth: "48px",
                                        }}
                                        key="help"
                                        icon={<QuestionCircleRegular />}
                                        onClick={() => { setShowHelpPanel(!showHelpPanel); }}
                                    />,
                                    <ToolbarButton
                                        style={{
                                            height: "48px",
                                            width: "48px",
                                            maxWidth: "48px",
                                        }}
                                        key="logout"
                                        onClick={() => {
                                            //on logout we nuke everything except theme and recent workspaces
                                            useStore.setState({
                                                isUserAuthenticated: false,
                                                endpoints: { authenticationAPI: "", baseAPI: "", questionnaireAPI: "" },
                                                availableWorkspaces: [],
                                                workspaceDirectory: { templates: [], clauses: [] },
                                                screenSize: ScreenSize.fullScreen,
                                                portalConfig: undefined,
                                                signalRUpdate: { userDocumentUpdated: "", dirEntriesUpdated: [] },
                                            });
                                            usePersistentStore.setState({ userObj: undefined });
                                            console.log('logging out')
                                            navigate("/logout", { replace: true });
                                        }}
                                        icon={<SignOutRegular />}
                                    />

                                ]}
                                screenSize={screenSize}
                                showSearch={showSearchbar}
                                isDark={isDark}
                                setIsDark={(dark) => {
                                    usePersistentStore.setState({ darkMode: dark });
                                }}
                                portalConfig={portalConfig}
                                disableSearch={selectedTab === "bulkgeneration"}
                            />

                            <div
                                style={{
                                    display: "flex",
                                    height: `calc(100% - ${headerHeight} - ${headerBottomPadding})`,
                                }}
                            >
                                {screenSize !== ScreenSize.mobile && (
                                    <LeftPaneNav
                                        selectedItem={selectedTab}
                                        setSelectedItem={ChangeActiveTab}
                                        otherWorkspaces={getLeftpaneWorkspaces(false)}
                                        recentWorkspaces={getLeftpaneWorkspaces(true)}
                                        selectedWorkspace={selectedWorkspace}
                                        setSelectedWorkspace={(workspace: string) => {
                                            changeWorkspace(workspace);
                                        }}
                                        screenSize={screenSize}
                                        userObj={userObj!}
                                        isCollapsed={isCollapsed}
                                        setIsCollapsed={setIsCollapsed}
                                        hasClauses={hasClauses}
                                        portalLinks={portalLinks?.find((x) => x.page === LinksPageTypes.Main)}
                                    />
                                )}

                                {(selectedTab === "templates" || selectedTab === "clauses" || selectedTab === "bulkgeneration") && (
                                    <div
                                        id="scrollContainerClausesTemplates"
                                        className="scroll animatePadding"
                                        style={{
                                            width: "100%",
                                            //paddingRight:
                                            //    screenSize !== ScreenSize.fullScreen ? "0px" : isCollapsed ? "68px" : "260px",
                                            paddingLeft: "16px",
                                            paddingRight: "40px",
                                            marginRight: "1px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                //margin: "0px auto",
                                                //padding:
                                                //    screenSize !== ScreenSize.mobile
                                                //        ? `${tokens.spacingVerticalXXXL} 50px 0px 50px`
                                                //        : "30px 10px 0px 4px",
                                                //maxWidth: screenSize !== ScreenSize.mobile ? "1120px" : "",
                                                minWidth: screenSize !== ScreenSize.mobile ? "500px" : "",
                                                marginBottom:
                                                    screenSize !== ScreenSize.mobile
                                                        ? tokens.spacingVerticalXXXL
                                                        : tokens.spacingVerticalNone,
                                            }}
                                        >
                                            {selectedTab === "templates" && selectedWorkspace && (
                                                <TemplatesTab
                                                    selectedWorkspace={selectedWorkspace}
                                                    setSelectedWorkspace={(id: string) => {
                                                        setSelectedWorkspace(id);
                                                        addRecentWorkspace(id);
                                                    }}
                                                    searchValue={searchValue}
                                                    setSearchValue={setSearchValue}
                                                    isFetching={isFetching}
                                                    setIsFetching={setIsFetching}
                                                    screenSize={screenSize}
                                                    setShowSearchbar={setShowSearchbar}
                                                    forceGoToRoot={forceGoToRoot}
                                                    helpPortalLink={helpUrl}
                                                />
                                            )}

                                            {selectedTab === "clauses" && (
                                                <ClausesTab
                                                    searchValue={searchValue}
                                                    setSearchValue={setSearchValue}
                                                    selectedWorkspace={selectedWorkspace}
                                                    setSelectedWorkspace={(id: string) => {
                                                        setSelectedWorkspace(id);
                                                        addRecentWorkspace(id);
                                                    }}
                                                    isFetching={isFetching}
                                                    setIsFetching={setIsFetching}
                                                    screenSize={screenSize}
                                                    setShowSearchbar={setShowSearchbar}
                                                    forceGoToRoot={forceGoToRoot}
                                                />
                                            )}
                                            {selectedTab === "bulkgeneration" && (
                                                <BulkGeneration
                                                    userObj={userObj!}
                                                    screenSize={screenSize}
                                                    recentWorkspaceIds={recentWorkspaces}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}

                                {selectedTab === "mydocuments" && (
                                    <DocumentsTab
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        isLeftPaneCollapsed={isCollapsed}
                                        setShowSearchbar={setShowSearchbar}
                                        signalRUpdateQueue={documentsSignalRUpdateQueue}
                                        consumeSignalRUpdate={() => {
                                            // Remove the first entry from the queue
                                            setDocumentsSignalRUpdateQueue((prevQueue) => prevQueue.slice(1));
                                        }}
                                        forceGoToRoot={forceGoToRoot}
                                        shareUsers={shareUsers}
                                    />
                                )}

                                {selectedTab === "workspaces" && (
                                    <WorkspacesTab
                                        selectedWorkspace={selectedWorkspace}
                                        setSelectedTab={ChangeActiveTab}
                                        setSelectedWorkspace={changeWorkspace}
                                        searchValue={searchValue}
                                        screenSize={screenSize}
                                    />
                                )}
                            </div>

                            {screenSize === ScreenSize.mobile && (
                                <LeftPaneNav
                                    selectedItem={selectedTab}
                                    setSelectedItem={ChangeActiveTab}
                                    otherWorkspaces={getLeftpaneWorkspaces(false)}
                                    recentWorkspaces={getLeftpaneWorkspaces(true)}
                                    selectedWorkspace={selectedWorkspace}
                                    setSelectedWorkspace={(workspace: string) => {
                                        changeWorkspace(workspace);
                                    }}
                                    screenSize={screenSize}
                                    userObj={userObj!}
                                    isCollapsed={isCollapsed}
                                    setIsCollapsed={setIsCollapsed}
                                />
                            )}
                        </div>

                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", height: "100dvh", overflow: "hidden" }}>
                            <MobileHeader
                                searchValue={searchValue}
                                onSearchChange={onSearchChange}
                                centerItems={getHeaderLogo(true)}
                                rightSideItems={[
                                    <ToolbarButton key="help" icon={<QuestionCircleRegular />} onClick={() => { setShowHelpPanel(!showHelpPanel); }} />,
                                    <ToolbarButton
                                        key="logout"
                                        onClick={() => {
                                            //on logout we nuke everything except theme and recent workspaces
                                            useStore.setState({
                                                isUserAuthenticated: false,
                                                endpoints: { authenticationAPI: "", baseAPI: "", questionnaireAPI: "" },
                                                availableWorkspaces: [],
                                                workspaceDirectory: { templates: [], clauses: [] },
                                                screenSize: ScreenSize.fullScreen,
                                                portalConfig: undefined,
                                                signalRUpdate: { userDocumentUpdated: "", dirEntriesUpdated: [] },
                                            });
                                            usePersistentStore.setState({ userObj: undefined });
                                            console.log('logging out')
                                            navigate("/logout", { replace: true });
                                        }}
                                        icon={<SignOutRegular />}
                                    />,
                                ]}
                                screenSize={screenSize}
                                showSearch={showSearchbar}
                                isDark={isDark}
                                setIsDark={(dark) => {
                                    usePersistentStore.setState({ darkMode: dark });
                                }}
                            />

                            {selectedTab === "templates" && selectedWorkspace && (
                                <TemplatesTab
                                    selectedWorkspace={selectedWorkspace}
                                    setSelectedWorkspace={(id: string) => {
                                        setSelectedWorkspace(id);
                                        addRecentWorkspace(id);
                                    }}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    isFetching={isFetching}
                                    setIsFetching={setIsFetching}
                                    screenSize={screenSize}
                                    setShowSearchbar={setShowSearchbar}
                                    forceGoToRoot={forceGoToRoot}
                                    helpPortalLink={helpUrl}
                                />
                            )}

                            {selectedTab === "clauses" && (
                                <ClausesTab
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    selectedWorkspace={selectedWorkspace}
                                    setSelectedWorkspace={(id: string) => {
                                        setSelectedWorkspace(id);
                                        addRecentWorkspace(id);
                                    }}
                                    isFetching={isFetching}
                                    setIsFetching={setIsFetching}
                                    screenSize={screenSize}
                                    setShowSearchbar={setShowSearchbar}
                                    forceGoToRoot={forceGoToRoot}
                                />
                            )}
                            {selectedTab === "mydocuments" && (
                                <DocumentsTab
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    isLeftPaneCollapsed={isCollapsed}
                                    setShowSearchbar={setShowSearchbar}
                                    signalRUpdateQueue={documentsSignalRUpdateQueue}
                                    consumeSignalRUpdate={() => {
                                        // Remove the first entry from the queue
                                        setDocumentsSignalRUpdateQueue((prevQueue) => prevQueue.slice(1));
                                    }}
                                    forceGoToRoot={forceGoToRoot}
                                    shareUsers={shareUsers}
                                />
                            )}

                            {selectedTab === "workspaces" && (
                                <WorkspacesTab
                                    selectedWorkspace={selectedWorkspace}
                                    setSelectedTab={ChangeActiveTab}
                                    setSelectedWorkspace={changeWorkspace}
                                    searchValue={searchValue}
                                    screenSize={screenSize}
                                />
                            )}

                            <BottomNavMobile
                                selectedItem={selectedTab}
                                setSelectedItem={ChangeActiveTab}
                                screenSize={screenSize}
                                userObj={userObj!}
                                hasClauses={hasClauses}

                            />
                        </div>
                    )}
                </>
            </CustomFluentProvider>
        </MainPageSignalRWrapper>
    );
};

