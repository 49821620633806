﻿import { IConfigLogin } from './ddentities';
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { FC, useEffect } from 'react';
import React from 'react';


interface loginProps {
    ConfigLogin: IConfigLogin;

}

export const PublicSiteRedirect: FC<loginProps> = (props) => {
    /* const [companyInfo, setCompanyInfo] = useState<IConfigLogin>(null);*/
    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;


  
    useEffect(() => {
        document.location.href = props.ConfigLogin.config.publicQuestionnaireReturnLink;

              
    }, []);


   
    return (

        <div
          
        >
        
        </div>

    );
}
