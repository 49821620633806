﻿import { GetAntiForgeryToken, getPortalConfigLogin, LoginAAD, LoginNative, VerifyLoginMethod, LoginViaEmail, LoginCheckViaEmail, AcceptLoginViaEmail, LoginWithDeepLinkToken } from '../apiFunctions/authenticateFunctions';
import { IConfigLogin, ILoginstatus } from './ddentities';
import { TextField, PrimaryButton, Stack, Link, mergeStyleSets, loadTheme, IPartialTheme, IPalette, ITextField } from '@fluentui/react';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import useStore from "../apiFunctions/store/store";
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { getEndpoints, setEndpoints, GetWorkspaces, getTranslation, usedTexts } from "documentdrafter-components";
import { IUserProps } from './entitiesAPI';
import validator from "validator";
import { FC, useEffect, useState, createRef } from 'react';
import { getCustomTheme } from "documentdrafter-components";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Divider,
    Field,
    Input,
    Spinner,
    themeToTokensObject,
    tokens,
    typographyStyles,
} from "@fluentui/react-components";
import React from 'react';

interface loginProps {
    ConfigLogin: IConfigLogin;



}

export const DeepLinkHandler: React.FC<loginProps> = (props) => {
    /* const [companyInfo, setCompanyInfo] = useState<IConfigLogin>(null);*/
    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [loaderMessage, setLoaderMessage] = useState(getTranslation(usedTexts.login.verifyingLink));


    const fetchAntiForgeryToken = async () => {
        await GetAntiForgeryToken();
    };

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
      
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const keys = queryParams.keys();
        const token = keys.next().value;
        
        
        fetchAntiForgeryToken();
        var init = async () => {

            var loginObj = await LoginWithDeepLinkToken(document.location.hostname, token);
            if (loginObj.status === "verified") {

                var csrfToken = getCookie("RequestVerificationToken");
                try {
                    var data = await fetch('/api/session', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'RequestVerificationToken': csrfToken
                        },
                        body: JSON.stringify(loginObj.session)
                    });
                } catch (e) {
                    console.error(e);
                    setLoaderMessage(getTranslation(usedTexts.login.invalidLink))
                    return;
                }

                sessionStorage.setItem("user", JSON.stringify(loginObj.session));
                const newUserObj: IUserProps = {
                    requestToken: "",
                    userid: "",
                    useremail: loginObj.session.portalUser.Description.email,
                    username: loginObj.session.portalUser.Description.name,
                    casenumberRequired: false,
                    customerName: "",
                    rightslist: loginObj.session.rightslist,
                    permissions: loginObj.session.permissions,
                    customerid: loginObj.session.customerid,
                    guestUser: loginObj.session.portalUser.Groups.indexOf("55555555") > -1,
                    mainUrl: loginObj.session.MainUrl,
                    isPublic: loginObj.session.portalUser.Groups.indexOf("00000000-2222-3333-444444444444") > -1,  
                };
                newUserObj.requestToken = loginObj.session.requestToken;
                newUserObj.userid = loginObj.session.portalUser.id;
                newUserObj.casenumberRequired = loginObj.session.casenumberrequired;
                newUserObj.customerName = loginObj.session.customername;

                useStore.setState({ isUserAuthenticated: true, portalConfig: loginObj.PortalConfig });
                sessionStorage.setItem("config", JSON.stringify(loginObj.PortalConfig));
                usePersistentStore.setState({ userObj: newUserObj });

                var portalUrl = window.location.hostname.indexOf('localhost') > -1 ? 'v2020.dev.documentdrafter.com' : window.location.hostname;
                var endpointResult = await getEndpoints(portalUrl);
                useStore.setState({ endpoints: endpointResult });
                setEndpoints(endpointResult);
            
                var workspacesResult = await GetWorkspaces(newUserObj);
                useStore.setState({ availableWorkspaces: workspacesResult });
                document.location = loginObj.nextEndpoint;
            }
            else {
                setLoaderMessage(getTranslation(usedTexts.login.invalidLink))
            }
        }
        init();

    }, []);

    
    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f3f2f1', // Replace with your theme color
        },
        box: {
            padding: '20px',
            backgroundColor: 'white', // Replace with your theme color
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '5px',
            maxWidth: '400px',
            width: '100%',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        logo: {
            width: '100px',
            height: '100px',
            marginBottom: '20px',
            // Add styles for your logo here
        },
    });

    let logoHeight = "40px";
    if (props.ConfigLogin.config?.loginLogoHeight?.length) {
        logoHeight = props.ConfigLogin.config.loginLogoHeight;
        if (logoHeight.indexOf("px") < 0)
            logoHeight += "px";
    }

    let logoMarginTop = "0px";
    if (props.ConfigLogin.config?.loginLogoTopMargin?.length) {
        logoMarginTop = props.ConfigLogin.config.loginLogoTopMargin;
        if (logoMarginTop.indexOf("px") < 0)
            logoMarginTop += "px";
    }

    function getLogo() {
        if (isDark) {
            if (props.ConfigLogin.config?.loginLogoPathDark?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPathDark}
                        alt="slim logo" />
                )

        }
        else {
            if (props.ConfigLogin.config?.loginLogoPath?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPath}
                        alt="slim logo"
                    />
                )
        }

        return null
    }

    return (

        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`,
            }}
        >
            <Card
                style={{
                    width: 380,
                    minHeight: 263,
                    padding: `${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXXL} ${tokens.spacingVerticalXXXL}`,
                    backgroundColor: tokens.colorNeutralBackground1,
                }}
            >
                <CardHeader
                    image={getLogo()}
                />

                {loaderMessage && (
                    <div style={{ transform: "translateY(20%)" }}>
                        {/*  <Spinner size={"medium"} />*/}

                        <div
                            style={{
                                ...typographyStyles.subtitle1,
                                textAlign: "center",
                                marginTop: tokens.spacingVerticalS,
                            }}
                        >
                            {loaderMessage}
                        </div>
                    </div>
                )}
               
            </Card>
        </div>

    );
}
