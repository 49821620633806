﻿import { GetAntiForgeryToken, getPortalConfigLogin, LoginAAD, LoginNative, VerifyLoginMethod, LoginViaEmail, LoginCheckViaEmail, AcceptLoginViaEmail, LoginLinkToDocument, LinkToDocumentAuthenticated } from '../apiFunctions/authenticateFunctions';
import { IConfigLogin, ILoginMethod, ILoginstatus } from './ddentities';
import { TextField, PrimaryButton, Stack, Link, mergeStyleSets, loadTheme, IPartialTheme, IPalette, ITextField } from '@fluentui/react';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import useStore from "../apiFunctions/store/store";
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { getEndpoints, setEndpoints, GetWorkspaces } from "documentdrafter-components";
import { IUserProps } from './entitiesAPI';
import validator from "validator";
import { FC, useEffect, useState, createRef } from 'react';
import { getCustomTheme } from "documentdrafter-components";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Divider,
    Field,
    Input,
    Spinner,
    themeToTokensObject,
    tokens,
    typographyStyles,
} from "@fluentui/react-components";
import React from 'react';

interface loginProps {
    ConfigLogin: IConfigLogin;
    Token: string


}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const LoginDocumentLinkScreen: React.FC<loginProps> = (props) => {
    /* const [companyInfo, setCompanyInfo] = useState<IConfigLogin>(null);*/
    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [loaderMessage, setLoaderMessage] = useState("Please Wait");
    const [userEmail, setUserEmail] = useState("");
    const isAuthenticated = useStore().isUserAuthenticated;


    useEffect(() => {

        var loginUser = localStorage.getItem("loginUser");
        if (!loginUser) {
            loginUser = "";
        }


        if (isAuthenticated) {
            LinkToDocumentAuthenticated(props.Token).then((result) => {

                if (result == "EXPIRED")
                    setLoaderMessage("Sorry - Link has expired");
                else {
                    setLoaderMessage("Redirecting to document");
                    window.location.href = result;
                }


            })
        }
        else
            LoginLinkToDocument(props.Token, loginUser).then((result) => {
                if (result.status.indexOf("OK:") > -1) {

                    var csrfToken = getCookie("RequestVerificationToken");
                    fetch('/api/session', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'RequestVerificationToken': csrfToken
                        },
                        body: JSON.stringify(result.session)
                    }).then(data => {

                        sessionStorage.setItem("user", JSON.stringify(result.session));
                        const newUserObj: IUserProps = {
                            requestToken: "",
                            userid: "",
                            useremail: result.session.portalUser.Description.email,
                            username: result.session.portalUser.Description.name,
                            casenumberRequired: false,
                            customerName: "",
                            rightslist: result.session.rightslist,
                            permissions: result.session.permissions,
                            customerid: result.session.customerid,
                            guestUser: result.session.portalUser.Groups.indexOf("55555555") > -1,
                            mainUrl: result.session.MainUrl

                        };
                        newUserObj.requestToken = result.session.requestToken;
                        newUserObj.userid = result.session.portalUser.id;
                        newUserObj.casenumberRequired = result.session.casenumberrequired;
                        newUserObj.customerName = result.session.customername;

                        useStore.setState({ isUserAuthenticated: true, portalConfig: result.PortalConfig });
                        sessionStorage.setItem("config", JSON.stringify(result.PortalConfig));

                        usePersistentStore.setState({ userObj: newUserObj });

                        var portalUrl = window.location.hostname.indexOf('localhost') > -1 ? 'v2020.dev.documentdrafter.com' : window.location.hostname;
                        getEndpoints(portalUrl).then((endpointsResult) => {


                            useStore.setState({ endpoints: endpointsResult });
                            setEndpoints(endpointsResult);
                            
                                //GetFolderStructure(authResult).then((dirResult) => {
                                GetWorkspaces(newUserObj).then((workspacesResult) => {
                                    useStore.setState({ availableWorkspaces: workspacesResult });

                                    let docid = result.status.split(":")[1];
                                    setLoaderMessage("Redirecting to document");
                                    window.location.href = "/document/" + docid;

                                });
                            
                        });

                    }
                    )

                }
                else if (result.status == "EXPIRED") {
                    setLoaderMessage("Sorry - Link has expired");
                }
                else if (result.status == "NEEDSMAIL") {
                    setLoaderMessage("");
                }
                else if (result.status == "MAILSEND") {
                    setLoaderMessage("New login link has been send to your email")
                }
                else if(result.status.startsWith("https://")) 
                {
                    window.location.href = result.status;
                }

                else {
                    setLoaderMessage("Sorry - Link has expired");
                }

            });


    }, []);

    let logoHeight = "40px";
    if (props.ConfigLogin.config?.loginLogoHeight?.length) {
        logoHeight = props.ConfigLogin.config.loginLogoHeight;
        if (logoHeight.indexOf("px") < 0)
            logoHeight += "px";
    }

    let logoMarginTop = "0px";
    if (props.ConfigLogin.config?.loginLogoTopMargin?.length) {
        logoMarginTop = props.ConfigLogin.config.loginLogoTopMargin;
        if (logoMarginTop.indexOf("px") < 0)
            logoMarginTop += "px";
    }

    function getLogo() {
        if (isDark) {
            if (props.ConfigLogin.config?.loginLogoPathDark?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPathDark}
                        alt="slim logo" />
                )

        }
        else {
            if (props.ConfigLogin.config?.loginLogoPath?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPath}
                        alt="slim logo"
                    />
                )
        }

        return null
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    async function LoginLinkEmail() {
        var result = await LoginLinkToDocument(props.Token, userEmail);
        if (result.status == "MAILSEND") {
            setLoaderMessage("New login link has been send to your email");
        }
        else if (result.status.startsWith("https://")) {

            if (result.loginMethod === ILoginMethod.office365) {
                setCookie("GMo365", 1, 365);
            }
            localStorage.setItem("loginUser", userEmail);
            window.location.href = result.status;
        }
        else {
            setLoaderMessage("Sorry - Link has expired");
        }
    }




    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f3f2f1', // Replace with your theme color
        },
        box: {
            padding: '20px',
            backgroundColor: 'white', // Replace with your theme color
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '5px',
            maxWidth: '400px',
            width: '100%',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        logo: {
            width: '100px',
            height: '100px',
            marginBottom: '20px',
            // Add styles for your logo here
        },
    });



    return (

        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`,
            }}
        >
            <Card
                style={{
                    width: 380,
                    minHeight: 263,
                    padding: `${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXXL} ${tokens.spacingVerticalXXXL}`,
                    backgroundColor: tokens.colorNeutralBackground1,
                }}
            >
                <CardHeader
                    image={getLogo()}
                />

                {loaderMessage ? (
                    <div style={{ transform: "translateY(20%)" }}>
                        {/*  <Spinner size={"medium"} />*/}

                        <div
                            style={{
                                ...typographyStyles.subtitle1,
                                textAlign: "center",
                                marginTop: tokens.spacingVerticalS,
                            }}
                        >
                            {loaderMessage}
                        </div>
                    </div>
                ) : (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                        <>
                            <div>
                                <div
                                    style={{
                                        ...typographyStyles.subtitle1,
                                        marginBottom: tokens.spacingVerticalSNudge,
                                    }}
                                >
                                    Please enter your email address
                                </div>

                                <Input
                                    value={userEmail}
                                    onChange={(_e, v) => {
                                        setUserEmail(v.value);
                                    }}
                                    onKeyDown={async (e) => {
                                        if (e.key === "Enter" && userEmail) {
                                            await LoginLinkEmail()

                                        }
                                    }}
                                    defaultValue={userEmail}
                                    placeholder="Email"
                                    autoFocus={true}
                                    style={{ width: "100%" }}
                                />
                            </div>

                            <CardFooter>
                                <Button
                                    style={{ width: "100%" }}
                                    appearance="primary"
                                    disabled={!userEmail}
                                    onClick={async () => {
                                        // This is how we mimic the loader/fetching
                                        await LoginLinkEmail();
                                    }}
                                >
                                    Next
                                </Button>
                            </CardFooter>

                        </>

                    </div>

                )}



            </Card>
        </div>

    );
}

